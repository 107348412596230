import React from 'react'

import { SeriesStatus } from '@guiker/propsharing-shared'
import { ColumnGridLayout } from '@guiker/react-framework'

import { useListingScreenContext } from '../../context'
import { ResultItem } from './ResultItem'

type CardResultsProps = {
  isDrawer?: boolean
}

export const CardResults: React.FC<CardResultsProps> = ({ isDrawer }) => {
  const {
    helpers: {
      currency,
      stock: { latestSeries, totalEquities, availableEquities, assetValue, pricePerUnit },
    },
  } = useListingScreenContext()

  return (
    <ColumnGridLayout gap={1}>
      <ResultItem
        i18nKey='stock.series.deal.propertyValue'
        name='stock.series.target.deal.propertyValue'
        value={assetValue}
        type='currency'
        withoutDecimal={true}
        currency={currency}
      />
      <ResultItem
        i18nKey='stock.totalEquities'
        type='currency'
        currency={currency}
        withoutDecimal={true}
        value={totalEquities}
      />
      {latestSeries.status !== SeriesStatus.CLOSED && (
        <ResultItem
          i18nKey='stock.availableEquities'
          type='currency'
          currency={currency}
          withoutDecimal={true}
          value={availableEquities}
        />
      )}
      {!isDrawer && (
        <ResultItem
          i18nKey='stock.series.pricePerUnit'
          type='currency'
          currency={currency}
          withoutDecimal={false}
          value={pricePerUnit}
        />
      )}
    </ColumnGridLayout>
  )
}
