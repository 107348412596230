import React, { useEffect, useState } from 'react'

import { ButtonWithLoader, SecondaryButton, Spinner } from '@guiker/components-library'
import { useMutation } from '@guiker/react-query'
import { useSentryContext } from '@guiker/sentry-context'
import {
  Account,
  CountryCode,
  useAuthenticatedPaymentVerificationApiClient,
  usePlaidLink,
} from '@guiker/use-plaid-link'

export type OnAccountSelectedArgs = {
  publicToken: string
  accountId: string
  account: Account
  auth?: {
    name: string
    routingNumber: string
    accountNumber: string
    mask: string
    email: string
  }
}

type PlaidLinkProps = {
  label: string
  countryCode: CountryCode
  plaidAccessToken?: string
  isLoading?: boolean
  openOnLoad?: boolean
  routingNumber?: string
  onSelect: (args: OnAccountSelectedArgs) => void
  onError?: () => void
}

type PlaidLinkContentProps = PlaidLinkProps & { linkToken: string }

const Content: React.FC<PlaidLinkContentProps> = (props) => {
  const { label, onSelect, onError, linkToken, countryCode, isLoading, openOnLoad = true } = props
  const { captureMessage } = useSentryContext()
  const apiClient = useAuthenticatedPaymentVerificationApiClient()

  const { mutateAsync: getBankAccount, isLoading: isLoadingBankAccount } = useMutation(
    (args: { publicToken: string; accountId: string }) => {
      const { publicToken, accountId } = args
      return apiClient.readPlaidBankAccountDetails({ payload: { publicToken, accountId, countryCode } })
    },
  )

  const { open, ready } = usePlaidLink({
    linkToken,
    onSuccess: (publicToken, accounts) => {
      const account = accounts[0]
      getBankAccount({ publicToken, accountId: account.id }).then((res) => {
        const { identity, mask, routingNumber, accountNumber } = res
        const auth = { name: identity.name, mask, routingNumber, accountNumber, email: identity.emailAddress }
        onSelect({ publicToken, auth, account, accountId: account.id })
      })
    },
    onEvent: (eventName, event) => {
      captureMessage({ message: eventName, messageInfo: event })
      eventName === 'EXIT' && onError?.()
    },
    onError: (errorCode, errorMessage) => {
      captureMessage({ message: errorMessage, messageInfo: { errorCode, errorMessage } })
      onError?.()
    },
  })

  useEffect(() => {
    openOnLoad && ready && open()
  }, [ready])
  useEffect(
    () => () => {
      document.body.style.overflow = 'visible'
    },
    [],
  )

  return (
    <ButtonWithLoader
      fullWidth
      type='button'
      variant='outlined'
      color='textPrimary'
      isLoading={!ready || isLoadingBankAccount || isLoading}
      buttonComponent={SecondaryButton}
      onClick={() => open()}
    >
      {label}
    </ButtonWithLoader>
  )
}

export const PlaidLink: React.FC<PlaidLinkProps> = (props) => {
  const { plaidAccessToken: accessToken, countryCode, routingNumber } = props
  const [linkToken, setLinkToken] = useState<string>()
  const apiClient = useAuthenticatedPaymentVerificationApiClient()

  const fetchLinkToken = async () => {
    const res = await apiClient.createPlaidLinkToken({
      payload: { countryCode, accessToken, routingNumber },
    })
    setLinkToken(res?.linkToken)
  }

  useEffect(() => {
    fetchLinkToken()
  }, [])

  if (!linkToken) {
    return <Spinner />
  }

  return <Content {...props} linkToken={linkToken} />
}
