import { PayoutMethodStatus } from '@guiker/payment-shared'
import { usePayoutContext } from '@guiker/payout-context'
import { hasSameCapabilities } from '@guiker/payout-shared'

export const useReplacementPayoutMethods = (payoutMethod) => {
  const { payoutMethods } = usePayoutContext()

  const potentalReplacementPayoutMethods = payoutMethods.filter((replacementPayoutMethod) => {
    const sameCapabilities = hasSameCapabilities({
      payoutMethod1: payoutMethod,
      payoutMethod2: replacementPayoutMethod,
    })

    const sameCurrency = replacementPayoutMethod.currency === payoutMethod.currency

    const isEnabled = replacementPayoutMethod.status === PayoutMethodStatus.ENABLED

    return replacementPayoutMethod.id !== payoutMethod.id && sameCapabilities && sameCurrency && isEnabled
  })

  return potentalReplacementPayoutMethods
}
