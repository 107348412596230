import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { FormSection3 } from '@guiker/components-library'
import { ScopeType } from '@guiker/contract-shared'
import { PaginatedTable, Pagination } from '@guiker/react-query-components'

import { useAuthenticatedContractApiClient } from '../../hooks'
import { useT } from '../../i18n'
import { useGetContractTableColumns } from '../../utils'

const PropSharingDocumentsTable: React.FC = () => {
  const apiClient = useAuthenticatedContractApiClient()
  const { user } = useAuthenticationContext()
  const { tMain } = useT({ screenName: 'screens.documents' })
  const columns = useGetContractTableColumns()

  const fetcher = (paginate: Required<Pagination>) =>
    apiClient.readAllByScope({
      queryParams: {
        ...paginate,
        scopeTypes: [
          ScopeType.WILLOW_USER,
          ScopeType.PROP_SHARING_BUY_STOCK_ORDER,
          ScopeType.PROP_SHARING_INVESTOR_PROFILE,
          ScopeType.PROP_SHARING_STOCK_ORDER_BUYOUT,
          ScopeType.PROP_SHARING_SERIES_SUBSCRIPTION_AGREEMENT,
        ],
        locale: null,
      },
    })

  return (
    <FormSection3 title={tMain('propSharingTable')}>
      <PaginatedTable
        queryKey={`buy-stock-order-${user?.id}`}
        fetcher={fetcher}
        columns={columns}
        pagination={{
          perPage: 10,
          sort: 'createdAt',
          sortOrder: -1,
        }}
      />
    </FormSection3>
  )
}

export { PropSharingDocumentsTable }
