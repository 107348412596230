import React, { useState } from 'react'

import { PayoutMethod } from '@guiker/payout-shared'
import { StepperModal, UseModal } from '@guiker/react-framework'

import { useT } from '../../../i18n'
import { ReplacementPayoutMethodConfirmation } from './ReplacementPayoutMethodConfirmation'
import { ReplacementPayoutMethodSelector } from './ReplacementPayoutMethodSelector'

type ReplacementModalProps = {
  modal: UseModal
  payoutMethod: PayoutMethod
}
export const ReplacementModal: React.FC<ReplacementModalProps> = ({ modal, payoutMethod }) => {
  const [replacement, setReplacement] = useState<PayoutMethod>()
  const { tBase } = useT({ screenName: 'components.listPayoutMethods.replacementModal' })
  return (
    <StepperModal
      modal={modal}
      steps={[
        {
          name: 'payoutMethodSelector',
          component: ({ onClickNext }) => (
            <ReplacementPayoutMethodSelector
              onClickNext={onClickNext}
              payoutMethod={payoutMethod}
              replacement={replacement}
              setReplacement={setReplacement}
            />
          ),
        },
        {
          name: 'confirmation',
          component: ({ onClickBack }) => (
            <ReplacementPayoutMethodConfirmation
              onClickBack={onClickBack}
              payoutMethod={payoutMethod}
              replacement={replacement}
              modal={modal}
              setReplacement={setReplacement}
            />
          ),
        },
      ]}
      finalLabel={tBase('actions.submit')}
      onClose={() => {
        setReplacement(undefined)
      }}
    />
  )
}
