import { Payment } from '@guiker/base-entity'
import { CurrencyISO } from '@guiker/money'

import { AccountHolder } from './account-holder'
import { PayoutProvider } from './payout-provider'
import { Verification } from './verification'
import { VerificationDocument } from './verification-document'

import PayoutMethodType = Payment.SupportedPayoutMethodType
import AccountHolderType = Payment.SupportedAccountHolderType
import PayoutTransactionType = Payment.TransactionType
import { Payout } from '..'

/** @description export enums renamed */
export { AccountHolderType, PayoutTransactionType, PayoutMethodType }

export enum PayoutMethodStatus {
  CREATED = 'CREATED',
  PENDING_SUBMISSION = 'PENDING_SUBMISSION',
  SUBMITTED = 'SUBMITTED',
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
  NEED_MORE_INFORMATION = 'NEED_MORE_INFORMATION',
  REJECTED = 'REJECTED',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export type Review = {
  reviewerId: string
  reviewerName: string
  reviewedAt: string
  comment: string
}

export type Consent = {
  agreed: boolean
  ipAddress: string
  date: string
}

export enum Capabilities {
  RENTAL = 'RENTAL',
  INVESTMENT = 'INVESTMENT',
}

export enum PayoutCapabilitiesType {
  payout = 'payout',
  investment = 'invest',
}

export type PayoutMethod = {
  id?: string
  userId: string
  currency: CurrencyISO
  type: PayoutMethodType
  status: PayoutMethodStatus
  providers: PayoutProvider[]
  verificationDocuments?: VerificationDocument[]
  verification?: Verification
  review?: Review
  accountHolderType: AccountHolderType
  accountHolder: AccountHolder
  consent?: Consent
  details: unknown
  capabilities: Capabilities[]
  isPlatformDefault?: boolean
}

export type PayoutMethodWithPayouts = PayoutMethod & {
  payouts: Payout[]
  balance: {
    inTransit: number
    total: number
  }
}

export type CanadianBankPayoutMethod = Omit<PayoutMethod, 'details' | 'currency' | 'type'> & {
  type: PayoutMethodType.CANADIAN_BANK_ACCOUNT
  currency: CurrencyISO.CAD
  details: {
    institutionNumber: string
    transitNumber: string
    accountNumber: string
    accountType: 'checking' | 'savings'
  }
}

export type USBankPayoutMethod = Omit<PayoutMethod, 'details' | 'currency' | 'type'> & {
  type: PayoutMethodType.US_BANK_ACCOUNT
  currency: CurrencyISO.USD
  details: {
    routingNumber: string
    accountNumber: string
    accountType: 'checking' | 'savings'
  }
}
