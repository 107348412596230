import React from 'react'

import { webappRoutes as accountRoutes } from '@guiker/account-shared'
import { Payout } from '@guiker/payout-app-components'
import {
  INCOMPLETE_STATUS,
  mainPathBuilder,
  NOTIFIED_STATUS,
  PayoutMethod as PayoutMethodEntity,
  payoutMethodInfoBuilder,
  PayoutMethodStatus,
} from '@guiker/payout-shared'
import {
  Chip,
  ConfirmDialog,
  DeleteIcon,
  Flex,
  IconButton,
  IconTooltip,
  PBold,
  RouterLink,
  TextButton,
  useLayoutContext,
  useModal,
  useMutation,
} from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient, useReplacementPayoutMethods } from '../../hooks'
import { useT } from '../../i18n'
import { CapabilityChip } from '../CapabilityChip'
import { PayoutMethodStatusChip } from '../PayoutMethodStatusChip'
import { ReplacementModal } from './ReplacementModal/ReplacementModal'

type PayoutMethodProps = {
  payoutMethod: PayoutMethodEntity
  isDeletable?: boolean
  refetch: () => void
}

const PayoutMethodToolTip: React.FC<Pick<PayoutMethodProps, 'payoutMethod'>> = ({ payoutMethod }) => {
  const { tMain } = useT({ screenName: 'components.listPayoutMethods' })
  if (!NOTIFIED_STATUS.includes(payoutMethod.status)) return
  return <IconTooltip title={tMain(`toolTip.${payoutMethod.status}`)} />
}

export const PayoutMethod: React.FC<PayoutMethodProps> = ({ payoutMethod, isDeletable = false, refetch }) => {
  const { tMain, tBase } = useT({ screenName: 'components.listPayoutMethods' })
  const label = payoutMethodInfoBuilder(payoutMethod).getDisplay()
  const last4digits = payoutMethodInfoBuilder(payoutMethod).getLast4Digits()
  const { isMobile } = useLayoutContext()
  const { openModal, closeModal, isOpen } = useModal()
  const apiClient = useAuthenticatedPayoutApiClient()

  const { mutate: deletePayoutMethod, isLoading } = useMutation(
    async () => apiClient.deletePayoutMethod({ pathParams: { payoutMethodId: payoutMethod.id } }),
    {
      onSuccess: () => {
        refetch()
      },
      onSettled: () => {
        closeModal()
      },
    },
  )

  const replacementPayoutMethods = useReplacementPayoutMethods(payoutMethod)
  const replacementModal = useModal()

  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <ConfirmDialog
        open={isOpen}
        onClose={closeModal}
        title={tMain('deletePayoutMethod.title')}
        description={tMain(`deletePayoutMethod.${!last4digits ? 'descriptionBlank' : 'description'}`, { label })}
        cancelButtonProps={{
          onClick: closeModal,
          children: tBase('actions.cancel'),
        }}
        confirmButtonProps={{ isLoading, onClick: deletePayoutMethod }}
      />
      <ReplacementModal payoutMethod={payoutMethod} modal={replacementModal} />
      <Flex gap={2} alignItems='center'>
        <Payout.PayoutMethodIcon payoutMethod={payoutMethod} />
        <Flex gap={isMobile ? 2 : 3} alignItems='center'>
          <PBold mb={0} whiteSpace='nowrap'>
            {label}
          </PBold>
          <Chip variant='outlined' color='info' size='small'>
            {tBase(`currency.${payoutMethod.currency}`)}
          </Chip>
          <PayoutMethodStatusChip payoutMethod={payoutMethod} />
          <CapabilityChip payoutMethod={payoutMethod} />
          <PayoutMethodToolTip payoutMethod={payoutMethod} />
        </Flex>
      </Flex>
      <Flex gap={2} alignItems='center'>
        {payoutMethod.status === PayoutMethodStatus.ENABLED ? (
          <RouterLink to={accountRoutes.root.wallet().payoutPreferences().payoutMethod(payoutMethod.id).path}>
            <TextButton>{tMain('seeDetails')}</TextButton>
          </RouterLink>
        ) : payoutMethod.status === PayoutMethodStatus.CHANGE_REQUESTED ? (
          <RouterLink to={mainPathBuilder.root.byId(payoutMethod.id).path}>
            <TextButton>{tMain('editPayout')}</TextButton>
          </RouterLink>
        ) : payoutMethod.status === PayoutMethodStatus.CREATED ? (
          <RouterLink to={mainPathBuilder.root.byId(payoutMethod.id).path}>
            <TextButton>{tMain('completePayout')}</TextButton>
          </RouterLink>
        ) : (
          <></>
        )}
        {replacementPayoutMethods.length > 0 && (
          <TextButton onClick={() => replacementModal.openModal()}>{tMain('replace')}</TextButton>
        )}
        {isDeletable && INCOMPLETE_STATUS.includes(payoutMethod.status) && (
          <IconButton onClick={openModal}>
            <DeleteIcon color={30} />
          </IconButton>
        )}
      </Flex>
    </Flex>
  )
}
