import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { PayoutMethod, PayoutProvider } from '../../entity'

export const EventsDefinition = generateEventsDefinition({
  context: 'PAYOUT',
  entity: 'PAYOUT_METHOD',
  data: null as PayoutMethod,
  eventTypes: {
    PAYOUT_PROVIDER_ACTIVATED: {
      type: 'PAYOUT_PROVIDER_ACTIVATED',
      eventData: {
        payoutProvider: null as PayoutProvider,
      },
    },
    PAYOUT_PROVIDER_UPDATED: {
      type: 'PAYOUT_PROVIDER_UPDATED',
      eventData: {
        payoutProvider: null as PayoutProvider,
      },
    },
    ENABLED: 'ENABLED',
    SUBMITTED: 'SUBMITTED',
    PENDING_SUBMISSION: 'PENDING_SUBMISSION',
    USER_SUBMITTED: 'USER_SUBMITTED',
    USER_COMPLETED: 'USER_COMPLETED',
    ADMIN_REVIEWAL_READY: 'ADMIN_REVIEWAL_READY',
    FAILED_ENABLE: 'FAILED_ENABLE',
    CHANGE_REQUESTED: 'CHANGE_REQUESTED',
    USER_CHANGE_REQUESTED: 'USER_CHANGE_REQUESTED',
    ADMIN_REVIEWED: 'ADMIN_REVIEWED',
    REJECTED: 'REJECTED',
    NEED_MORE_INFORMATION: 'NEED_MORE_INFORMATION',
    ACTIVATION_REQUESTED: 'ACTIVATION_REQUESTED',
    REPLACED: {
      type: 'REPLACED',
      eventData: {
        currentPayoutMethodId: null as string,
      },
    },
  } as const,
})
export const { events: Events, eventTypes: EventTypes } = EventsDefinition
export type EventType = ValueOfEventTypes<typeof EventsDefinition>
