import { routesBuilder } from '@guiker/shared-framework'

import { PayInMethod } from '../../../../entity'
import { resolveInsufficientFundFlaggedPayloadSchema } from '../../schemas'
import { paths } from '../paths'

const builder = routesBuilder<PayInMethod>()({ basePath: paths.payInMethod.all.withId(':payInMethodId') })

export const routes = {
  markVerificationTokenRefreshed: builder.buildPut({ path: '/mark-verification-token-refreshed' }),
  deleteOnePayInMethod: builder.buildDelete({ response: null as void }),
  readOnePayInMethod: builder.buildGet({}),
  resolvePlaidTokenExpired: builder.buildPut({ path: '/resolve-plaid-token-expired' }),
  resolveInsufficientFundFlagged: builder.buildPut({
    path: '/resolve-insufficient-fund-flagged',
    payloadSchema: resolveInsufficientFundFlaggedPayloadSchema,
    response: {} as PayInMethod,
  }),
} as const
