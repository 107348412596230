import { LeaseRoleNames, Person } from '../../entity'

export const eventTypes = {
  COLLABORATORS_REPLACED: 'COLLABORATORS_REPLACED',
  LEASE_PREPARED: {
    type: 'LEASE_PREPARED',
    eventData: null as {
      signers: Person[]
    },
  },
  GUARANTOR_INVITED_TO_SIGN: {
    type: 'GUARANTOR_INVITED_TO_SIGN',
    eventData: null as {
      guarantor: Person
      lessee: Person
    },
  },
  SIGNED_BY_LESSOR: {
    type: 'SIGNED_BY_LESSOR',
    eventData: null as {
      lessor: Person
    },
  },
  SIGNED_BY_LESSEE: {
    type: 'SIGNED_BY_LESSEE',
    eventData: null as {
      lessee: Person
    },
  },
  CONFIRMED: 'CONFIRMED',
  INITIALIZED: 'INITIALIZED',
  INITIALIZED_FROM_BOOKING: 'INITIALIZED_FROM_BOOKING',
  LESSORS_OR_LESSEES_UPDATED: 'LESSORS_OR_LESSEES_UPDATED',
  READY_TO_BE_SIGNED: 'READY_TO_BE_SIGNED',
  SIGNED: {
    type: 'SIGNED',
    eventData: null as {
      signedBy: string
      role: LeaseRoleNames
    },
  },
  SIGNED_BY_ALL_PARTIES: 'SIGNED_BY_ALL_PARTIES',
  UPDATED_LEASE_TERM_AND_RENT: 'UPDATED_LEASE_TERM_AND_RENT',
} as const
