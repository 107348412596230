import { money } from '@guiker/shared-framework'

import { Portfolio, Position, ShareJournalEntry, Stock, StockOrder, StockOrderExecution } from '../entity'
import { hydrateStock } from './stock'

export const hydratePosition = (position: Position) => {
  position.stock = hydrateStock(position.stock)

  return position
}

const computePerformanceInfoFromRatio = (ratio: number) => {
  const { performanceSign, isPositive } =
    ratio >= 1 ? { performanceSign: '+', isPositive: true } : { performanceSign: '', isPositive: false }
  const performancePercent = Math.round((ratio - 1) * 10000) / 100
  return { performanceSign, isPositive, performancePercent }
}

export const computePerformance = (position: Position, totalQuantity: number, purchasePrice: number) => {
  const netAssetValuePerUnit = position.stock.latestFinancialStatements?.shareholderEquityStatement.netAssetValuePerUnit
  const netAssetValue = netAssetValuePerUnit * totalQuantity

  const performanceRatio = netAssetValue / purchasePrice

  const { performanceSign, isPositive, performancePercent } = computePerformanceInfoFromRatio(performanceRatio)

  return {
    netAssetValue,
    netAssetValuePerUnit,
    purchasePrice,
    performanceRatio,
    performancePercent,
    performanceSign,
    isPositive,
  }
}

export const getStockOrderExecutionStats = (position: Position, stockOrderExecution: StockOrderExecution) => {
  return computePerformance(
    position,
    stockOrderExecution.price.quantity,
    stockOrderExecution.price.quantity * stockOrderExecution.price.amount,
  )
}

export const getStockOrderStats = (position: Position, stockOrder: StockOrder) => {
  return computePerformance(
    position,
    stockOrder.share.quantity,
    stockOrder.share.quantity * stockOrder.share.askedPrice,
  )
}

export const getShareJournalEntryStats = (position: Position, shareJournalEntry: ShareJournalEntry) => {
  return computePerformance(
    position,
    shareJournalEntry.unit.quantity,
    shareJournalEntry.unit.quantity * shareJournalEntry.unit.price,
  )
}

export const getPositionStats = (position: Position) => {
  const purchaseEntries = position.entries.filter((e) => {
    return e?.investorProfile?.id === position?.investorProfile?.id
  })
  const totalPurchase = purchaseEntries.reduce(
    (acc, e) => ({
      cost: acc.cost + e.share.askedPrice * e.share.quantity,
      quantity: acc.quantity + e.share.quantity,
    }),
    { cost: 0, quantity: 0 },
  )

  const averageCost = totalPurchase.cost / totalPurchase.quantity
  return computePerformance(position, position.total.quantity, averageCost * position.total.quantity)
}

export const getPortfolioStats = (portfolio: Portfolio) => {
  let currency = money.currency.CAD

  const { balance, positionOwned, targetEquity, bestPerformingStock } = portfolio.reduce(
    (acc, position) => {
      currency = money.currency[position.stock.currency]
      const positionStats = getPositionStats(position)
      const { performanceRatio, netAssetValue, purchasePrice } = positionStats

      return {
        ...acc,
        bestPerformingStock:
          performanceRatio > acc.bestPerformingStock.value
            ? { stock: position.stock, ...positionStats }
            : acc.bestPerformingStock,
        positionOwned: acc.positionOwned + position.total.quantity,
        balance: acc.balance + netAssetValue,
        targetEquity: acc.targetEquity + purchasePrice,
      }
    },
    {
      balance: 0,
      targetEquity: 0,
      positionOwned: 0,
      bestPerformingStock: {
        stock: null as Stock,
        value: 0,
        netAssetValue: 0,
        purchasePrice: 0,
        performanceRatio: 1,
        performancePercent: '',
        performanceSign: '',
        isPositive: true,
      },
    },
  )

  const { performanceSign, isPositive, performancePercent } = computePerformanceInfoFromRatio(
    balance && targetEquity ? balance / targetEquity : 1,
  )

  return {
    currency,
    balance: money.fromAmount(balance, currency),
    targetEquity: money.fromAmount(targetEquity, currency),
    positionOwned,
    performanceSign,
    performancePercent,
    isPositive,
    propertyInvestedIn: portfolio.length,
    bestPerformingStock,
  }
}
