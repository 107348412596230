import { useSearchParams as baseUseSearchParams } from 'react-router-dom'

import { isArray, isNil, isString } from '@guiker/lodash'

export const useSearchParams = () => {
  const [baseSearchParams, setBaseSearchParams] = baseUseSearchParams()

  const _add = (key: string, value: string | string[]) => {
    if (Array.isArray(value)) {
      baseSearchParams.delete(key)
      value.map((v) => baseSearchParams.append(key, v))
      setBaseSearchParams(baseSearchParams)
    } else {
      baseSearchParams.set(key, value)
      setBaseSearchParams(baseSearchParams)
    }
  }

  const _remove = (key: string) => {
    baseSearchParams.delete(key)
    setBaseSearchParams(baseSearchParams)
  }

  const getParam = (key: string): string => {
    const params = baseSearchParams.getAll(key)

    if (isArray(params) && params?.length) return params[0]
    else if (typeof params === 'string') return params
    else return undefined
  }

  const getArrayParam = (key: string) => {
    const param = baseSearchParams.getAll(key)
    if (!!param && typeof param === 'string') return [param]
    return param
  }

  const setParam = <T extends string>(key: string, value?: T | T[]) => {
    value ? _add(key, value) : _remove(key)
  }

  const replace = (params: Record<string, unknown>) => {
    const sanitized = Object.entries(params).reduce((acc, [key, value]) => {
      if (isNil(value)) return acc
      else if (isArray(value)) return { ...acc, [key]: value.map((v) => v.toString()) }
      else if (!isString(value)) return { ...acc, [key]: value.toString() }
      return { ...acc, [key]: value }
    }, {} as Record<string, string | string[]>)

    clear()
    setBaseSearchParams(sanitized, { replace: true })
  }

  const clear = () => setBaseSearchParams({})

  return {
    getParam,
    getArrayParam,
    setParam,
    replace,
    clear,
  }
}
