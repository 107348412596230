import React, { useState } from 'react'

import { Modal } from '../../Feedback'

export type ModalStepProps = {
  onClickNext: (targetStep?: number) => void
  onClickBack: () => void
  isFirstStep: boolean
  backLabel?: string
  nextLabel?: string
  isLoading?: boolean
}

export type StepperModalProps = {
  steps: {
    name: string
    component: React.FC<ModalStepProps>
  }[]
  onLastStepClicked?: () => void
  nextLabel?: string
  backLabel?: string
  isLoading?: boolean
  finalLabel: string
  modal: {
    isOpen: boolean
    openModal: () => void
    closeModal: () => void
  }
  onClose?: () => void
}

export const StepperModal: React.FC<StepperModalProps> = ({
  modal,
  nextLabel,
  finalLabel,
  steps,
  onLastStepClicked,
  isLoading,
  backLabel,
  onClose,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const isLastStep = currentStepIndex >= steps.length - 1

  const Step = steps[currentStepIndex].component

  const onClickNext = (targetStep?: number) => {
    if (targetStep || targetStep === 0) {
      setCurrentStepIndex(targetStep)
      return
    }
    if (isLastStep) {
      onLastStepClicked && onLastStepClicked()
      return
    }
    setCurrentStepIndex(currentStepIndex + 1)
  }

  const onClickBack = () => {
    if (currentStepIndex === 0) {
      return
    }
    setCurrentStepIndex(currentStepIndex - 1)
  }

  return (
    <Modal
      open={modal.isOpen}
      onClose={() => {
        setCurrentStepIndex(0)
        onClose?.()
        modal.closeModal()
      }}
    >
      <Step
        isFirstStep={currentStepIndex === 0}
        onClickNext={onClickNext}
        onClickBack={onClickBack}
        nextLabel={isLastStep ? finalLabel || nextLabel : nextLabel}
        backLabel={backLabel}
        isLoading={isLoading}
      />
    </Modal>
  )
}
