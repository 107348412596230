import { GeneratePublicReadUrlResult } from '@guiker/aws-s3-client'

import { Locale } from './locale'

export interface ContractParticipant {
  userId: string
}

export enum ScopeType {
  LEASE = 'Lease',
  PROP_SHARING_BUY_STOCK_ORDER = 'prop-sharing-buy-stock-order',
  PROP_SHARING_INVESTOR_PROFILE = 'prop-sharing-investor-profile',
  PROP_SHARING_STOCK_ORDER_BUYOUT = 'prop-sharing-stock-order-buyout',
  WILLOW_USER = 'willow-user',
  PROP_SHARING_SERIES_SUBSCRIPTION_AGREEMENT = 'prop-sharing-series-subscription-agreement',
}

type Contract = {
  id: string
  bucket: string
  key: string
  createdAt: string
  updatedAt: string
  locale: Locale
  scope: {
    type: ScopeType
    id: string
  }
  participants: ContractParticipant[]
  publicReadUrlResult?: GeneratePublicReadUrlResult
  displayName: string
}

export { Contract }
