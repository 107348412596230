import React from 'react'

import {
  DisputeStatus,
  FAILED_STATUS,
  IMMUTABLE_STATUS,
  Invoice,
  InvoiceStatus,
  SUCCESS_STATUS,
} from '@guiker/payment-shared'
import { PaginatedTable, PaginatedTableProps, Pagination } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { getInvoiceColumns } from './invoice-columns'

const StatusMap = {
  paid: SUCCESS_STATUS,
  processing: IMMUTABLE_STATUS,
  bad: FAILED_STATUS,
  other: [InvoiceStatus.SENT, InvoiceStatus.CANCELED, InvoiceStatus.REFUNDED, InvoiceStatus.DRAFTED] as InvoiceStatus[],
}

type InvoiceTableProps<StatusGroupName extends string = never, TabValue extends object = never> = Pick<
  PaginatedTableProps<Invoice, StatusGroupName, InvoiceStatus, TabValue, true, { disputeStatuses: DisputeStatus[] }>,
  'queryKey' | 'fetcher' | 'tabs'
> & {
  withSearch?: boolean
  withGroups?: boolean
  pagination?: Partial<Pagination>
}

export const InvoiceTable = <StatusGroupName extends string = never, TabValue extends object = never>({
  fetcher,
  pagination,
  queryKey,
  tabs,
  withGroups = true,
  withSearch = true,
}: InvoiceTableProps<StatusGroupName, TabValue>) => {
  const { tShared } = useT({ entity: 'invoice' })
  const { tShared: tSharedStatus } = useT({ entity: 'status' })

  return (
    <PaginatedTable
      initFromSearchParams={true}
      queryKey={queryKey}
      fetcher={fetcher}
      columns={getInvoiceColumns()}
      tabs={tabs}
      search={withSearch}
      pagination={pagination}
      filters={{
        disputeStatuses: {
          type: 'FilterMultiSelect',
          name: 'disputeStatuses',
          label: tShared('filters.disputeStatusTitle'),
          inputLabel: tShared('filters.disputeStatus'),
          options: Object.values(DisputeStatus).map((status) => ({
            value: status,
            label: tShared(`disputed.${status}`),
          })),
        },
      }}
      statusGroups={
        withGroups
          ? {
              mapper: StatusMap,
              statuses: Object.keys(InvoiceStatus).map((status) => ({
                type: status,
                label: tSharedStatus(status),
              })),
              groups: [
                { name: 'all', label: tShared('statusGroup.all') },
                { name: 'paid', label: tShared('statusGroup.paid') },
                { name: 'processing', label: tShared('statusGroup.processing') },
                { name: 'bad', label: tShared('statusGroup.bad') },
                { name: 'other', label: tShared('statusGroup.other') },
              ],
            }
          : undefined
      }
    />
  )
}
