import React, { useMemo } from 'react'

import {
  getPositionStats,
  getStockOrderStats,
  Portfolio as PortfolioType,
  Position,
  StockOrder,
  StockOrderStatus,
  webappRoutes,
} from '@guiker/propsharing-shared'
import {
  ColumnConfig,
  Flex,
  PSmall,
  PSmaller,
  RouterLink,
  Table,
  TableProps,
  TextButton,
  TFunction,
  useTranslation,
} from '@guiker/react-framework'
import { compact, money } from '@guiker/shared-framework'

import { PerformanceIndicator } from '../PerformanceIndicator'
import { StatusChip } from './StatusChip'

type PortfolioProps = {
  noCta?: boolean
  portfolio: PortfolioType
  emptyState?: TableProps<Position>['emptyState']
}

type PositionEntry = Omit<StockOrder, 'share'> & {
  share: StockOrder['share'] & { purchaseQuantity?: number }
}

const getColumns = (t: TFunction, noCta: boolean): ColumnConfig<Position, PositionEntry>[] => {
  const stockPrefix = 'common-propsharing:stock'
  const portfolioPrefix = 'common-propsharing:portfolio'

  const aggregatePositionEntries = (e1: PositionEntry, e2: PositionEntry, isPlus: boolean): PositionEntry => {
    const { askedPrice, quantity } = e2.share
    return {
      ...(e1 ?? e2),
      share: {
        askedPrice: (e1?.share.askedPrice ?? 0) + (isPlus ? askedPrice * quantity : 0),
        quantity: (e1?.share.quantity ?? 0) + (isPlus ? quantity : -quantity),
        purchaseQuantity: (e1?.share.purchaseQuantity ?? 0) + (isPlus ? quantity : 0),
      },
    }
  }

  const computePrice = (e: PositionEntry): PositionEntry => {
    return e
      ? {
          ...e,
          share: {
            askedPrice: Math.round(e.share.askedPrice / e.share.purchaseQuantity),
            quantity: e.share.quantity,
          },
        }
      : undefined
  }

  const collapsibleValues = (position: Position) => {
    const { issued, reserved } = position.entries.reduce((acc, e) => {
      const isBuy = e?.investorProfile?.id === position?.investorProfile?.id
      const isIssued = !isBuy || (isBuy && e.status === StockOrderStatus.FULFILLED)
      return isIssued
        ? { issued: aggregatePositionEntries(acc.issued, e, isBuy), reserved: acc.reserved }
        : { issued: acc.issued, reserved: aggregatePositionEntries(acc.reserved, e, true) }
    }, {} as { issued?: PositionEntry; reserved?: PositionEntry })

    return compact([computePrice(issued), computePrice(reserved)])
  }

  return [
    {
      name: 'stock.label',
      size: 1.5,
      renderValue: ({ index, ...position }) => {
        return [position.stock.tickerSymbol, position.stock.label]
      },
      collapsibleValues,
    },
    {
      label: t(`${portfolioPrefix}.unitOwned.label`),
      name: 'unitOwned',
      headerOptions: {
        textAlign: 'right',
      },
      renderValue: (position) => {
        return t(`${portfolioPrefix}.unitOwned.value`, {
          value: position.total.quantity,
        }).toString()
      },
      collapsibleValues,
      renderCollapsibleValue: (order) => {
        return t(`${portfolioPrefix}.unitOwned.value`, { value: order.share?.quantity }).toString()
      },
    },
    {
      label: t(`${portfolioPrefix}.purchasePrice.label`),
      headerOptions: {
        textAlign: 'right',
      },
      name: 'purchasePrice',
      renderValue: (position) => {
        const { purchasePrice } = getPositionStats(position)

        return money.fromAmount(purchasePrice, position.stock.currency).toString('onlySymbol')
      },
      collapsibleValues,
      renderCollapsibleValue: ({ parent: position, ...entry }) => {
        return money
          .fromAmount(entry.share.askedPrice * entry.share.quantity, position.stock.currency)
          .toString('onlySymbol')
      },
    },
    {
      label: t(`${portfolioPrefix}.netAssetValuePerUnit.label`),
      headerOptions: {
        textAlign: 'right',
      },
      name: 'netAssetValuePerUnit',
      collapsibleValues,
      renderValue: (position) => {
        const { netAssetValuePerUnit } = getPositionStats(position)

        return money.fromAmount(netAssetValuePerUnit, position.stock.currency).toString('onlySymbol')
      },
    },
    {
      label: t(`${portfolioPrefix}.netAssetValue.label`),
      headerOptions: {
        textAlign: 'right',
      },
      name: 'netAssetValue',
      renderValue: (position) => {
        const { netAssetValue } = getPositionStats(position)

        return money.fromAmount(netAssetValue, position.stock.currency).toString('onlySymbol')
      },
      collapsibleValues,
      renderCollapsibleValue: ({ parent: position, ...entry }) => {
        const { netAssetValue } = getStockOrderStats(position, entry)
        return money.fromAmount(netAssetValue, position.stock.currency).toString('onlySymbol')
      },
    },
    {
      label: t(`${portfolioPrefix}.performance.label`),
      headerOptions: {
        textAlign: 'right',
      },
      name: 'performance',
      renderValue: (position) => {
        const { performancePercent, performanceSign, isPositive } = getPositionStats(position)

        return (
          <Flex gap={1} alignItems='center' justifyContent='flex-end'>
            {isPositive != null && <PerformanceIndicator size='small' isPositive={isPositive} />}
            <PSmall mb={0}>{`${performanceSign}${performancePercent}%`}</PSmall>
          </Flex>
        )
      },
      collapsibleValues,
      renderCollapsibleValue: ({ parent: position, ...entry }) => {
        const { performancePercent, performanceSign, isPositive } = getStockOrderStats(position, entry)

        return (
          <Flex gap={1} alignItems='center' justifyContent='flex-end'>
            {isPositive != null && <PerformanceIndicator size='small' isPositive={isPositive} />}
            <PSmaller mb={0}>{`${performanceSign}${performancePercent}%`}</PSmaller>
          </Flex>
        )
      },
    },
    {
      name: 'stock.status',
      headerOptions: {
        textAlign: 'center',
      },
      renderValue: (position) => {
        return (
          !noCta && (
            <Flex flexDirection='column' alignItems='center'>
              <RouterLink to={`${webappRoutes.listings.path}/${position.stock.tickerSymbol}`}>
                <TextButton size='small'>{t(`${stockPrefix}.buyMore`)}</TextButton>
              </RouterLink>
            </Flex>
          )
        )
      },
      collapsibleValues,
      renderCollapsibleValue: (entry) => {
        return (
          <Flex flexDirection='column' alignItems='center'>
            <StatusChip size='small' status={entry.status} />
          </Flex>
        )
      },
    },
  ]
}

export const Portfolio: React.FC<PortfolioProps> = ({ portfolio, noCta = false, emptyState }) => {
  const [t] = useTranslation()
  const columns = useMemo(() => getColumns(t, noCta), [portfolio, noCta])

  return <Table data={portfolio} columns={columns} isRowCollapsible emptyState={emptyState} condensed />
}
