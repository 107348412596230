import React, { useEffect, useMemo, useState } from 'react'

import {
  BookingActions,
  BookingLeaseStatus,
  BookingStatus,
  checkConfirmReadiness,
  hasConfirmFailed,
  haveApplicantsCompletedOneStep as getHaveApplicantsCompletedOneStep,
  Participant,
  ParticipantStepStatus,
} from '@guiker/booking-shared'
import { Flex, IconTooltip, ProgressTracker, useTranslation } from '@guiker/react-framework'
import { compact } from '@guiker/shared-framework'

import { calculateSteps, generateProgressTrackerItemBuilder } from '../../../../'
import { useBookingContext, useLeaseDownloadLink } from '../../../../hooks'
import { Countdown } from '../Countdown'
import { SectionHeader } from '../SectionHeader'
import { ApplicationStatus } from './ApplicationStatus'
import { LeaseStatusLessee, LeaseStatusLessor } from './LeaseStatus'
import { RoommateStatus } from './RoommateStatus'
import { UnitManagerBookingPayment } from './UnitManagerBookingPayment'

const BookingSteps: React.FC = () => {
  const { t } = useTranslation(['main-booking'])
  const { booking, bookingUser, can, linkPath } = useBookingContext()
  const { leaseDownloadLink } = useLeaseDownloadLink(booking)

  const { enabledSteps } = useMemo(() => {
    return calculateSteps(booking, bookingUser as Participant)
  }, [booking.hasEnabledStep])

  const applicationReady = ![ParticipantStepStatus.NOT_READY, ParticipantStepStatus.PROCESSING].includes(
    enabledSteps.application?.status,
  )

  const roommateProfileReady = ![ParticipantStepStatus.NOT_READY].includes(enabledSteps.roommate?.status)

  const isBooked = booking?.status === BookingStatus.BOOKED
  const canDraftLease = can(BookingActions.DraftLease)
  const canViewBookingDocument = can(BookingActions.ViewBookingDocument)
  const canEditBookingDocument = can(BookingActions.EditBookingDocument, { adminOnly: true })
  const canGetBookingPayment = can(BookingActions.GetBookingPayment)
  const applicantId = booking.applicants.length === 1 ? booking.applicants[0].userId : undefined

  const steps = {
    application: {
      enabled: enabledSteps.application?.enabled,
      item: generateProgressTrackerItemBuilder(enabledSteps.application),
    },
    roommate: {
      enabled: enabledSteps.roommate?.enabled,
      item: generateProgressTrackerItemBuilder(enabledSteps.roommate),
    },
    document: {
      enabled:
        enabledSteps.document?.enabled &&
        (canEditBookingDocument ||
          (canViewBookingDocument && ![ParticipantStepStatus.NOT_STARTED].includes(enabledSteps.document.status))),
      item: generateProgressTrackerItemBuilder(enabledSteps.document),
    },
    lease: {
      enabled: enabledSteps.lease?.enabled,
      item: generateProgressTrackerItemBuilder(enabledSteps.lease),
    },
    payment: {
      enabled: enabledSteps.payment?.enabled && canGetBookingPayment,
      item: generateProgressTrackerItemBuilder(enabledSteps.payment),
    },
  }

  return (
    <ProgressTracker
      items={compact([
        steps.application.enabled &&
          steps.application.item((step) => ({
            content: t(`unitManagerBookingSteps.applicationStep_${step.status.toLowerCase()}`),
            collapsible: (
              <Flex flexDirection='column' gap={1} fullWidth>
                {booking.applicants?.map((applicant) => (
                  <ApplicationStatus key={applicant.userId} booking={booking} applicant={applicant} />
                ))}
              </Flex>
            ),
            link:
              applicationReady && !!applicantId
                ? linkPath.applicants.byApplicantUserId(applicantId).application.path
                : linkPath.applicants.applications.path,
          })),
        steps.roommate.enabled &&
          steps.roommate.item((step) => {
            return {
              content: t(`unitManagerBookingSteps.roommateStep_${step?.status?.toLowerCase()}`),
              collapsible: (
                <Flex flexDirection='column' gap={1} fullWidth>
                  {booking.applicants?.map((applicant) => (
                    <RoommateStatus key={applicant.userId} booking={booking} applicant={applicant} />
                  ))}
                </Flex>
              ),
              link:
                roommateProfileReady && applicantId
                  ? linkPath.applicants.byApplicantUserId(applicantId).roommateProfile.path
                  : undefined,
            }
          }),
        steps.document.enabled &&
          steps.document.item((step) => ({
            content: t(`unitManagerBookingSteps.documentStep_${step.status?.toLowerCase()}`),
            link:
              canEditBookingDocument && step.status === ParticipantStepStatus.NOT_STARTED
                ? linkPath.document.new.path
                : linkPath.document.view.path,
          })),
        steps.lease.enabled &&
          steps.lease.item((step) => ({
            content: t(`unitManagerBookingSteps.leaseStep_${step.status.toLowerCase()}`),
            collapsible: booking.lease?.participants ? (
              <Flex flexDirection='column' gap={1} fullWidth>
                {booking.applicants.map((applicant) => (
                  <LeaseStatusLessee key={applicant.userId} booking={booking} lessee={applicant} />
                ))}
                <LeaseStatusLessor booking={booking} />
              </Flex>
            ) : undefined,
            isExternalLink: isBooked,
            link: isBooked ? leaseDownloadLink : canDraftLease ? linkPath.lease.path : linkPath.lease.view.path,
          })),
        steps.payment.enabled &&
          steps.payment.item((step) => ({
            content: t(`unitManagerBookingSteps.paymentStep_${step.status.toLowerCase()}`),
            collapsed: step.status !== ParticipantStepStatus.FAILED,
            collapsible: <UnitManagerBookingPayment />,
            link: step.status === ParticipantStepStatus.COMPLETED && isBooked ? linkPath.rentPayment.path : undefined,
          })),
      ])}
    />
  )
}

const UnitManagerBookingSteps: React.FC = () => {
  const { t } = useTranslation(['main-booking'])
  const { booking, bookingUser, can } = useBookingContext()
  const { isReady: readyToConfirm } = checkConfirmReadiness(booking)

  const isBooked = booking?.status === BookingStatus.BOOKED
  const haveApplicantsCompletedOneStep = getHaveApplicantsCompletedOneStep(booking)
  const confirmFailed = hasConfirmFailed(booking)
  const [isConfirmable, setIsConfirmable] = useState(readyToConfirm || confirmFailed)

  const { enabledSteps } = useMemo(() => {
    return calculateSteps(booking, bookingUser as Participant)
  }, [booking.hasEnabledStep])

  const canConfirmBooking = can(BookingActions.ConfirmBooking) && !isBooked && readyToConfirm
  const canGetBookingSteps = can(BookingActions.GetBookingSteps)
  const tPrefix = 'main-booking:unitManagerBookingSteps'

  useEffect(() => {
    setIsConfirmable(readyToConfirm || confirmFailed)
  }, [readyToConfirm, confirmFailed])

  const tooltip = isConfirmable
    ? t(`${tPrefix}.${canConfirmBooking ? 'captionAllStepsCompleted' : 'captionWaitingForUMConfirm'}`)
    : t(`${tPrefix}.${haveApplicantsCompletedOneStep ? 'captionStarted' : 'captionNotStarted'}`)

  const hasBeenSignedByAllParties = booking.lease?.status === BookingLeaseStatus.SIGNED_BY_ALL_PARTIES
  const isExpired = booking.status === BookingStatus.EXPIRED
  const { expiresAt, startsAt } = { ...booking.timer }
  const showCountdown =
    ((isExpired && !hasBeenSignedByAllParties) || !hasBeenSignedByAllParties) && startsAt && expiresAt

  if (!Object.values(enabledSteps).some((step) => step.enabled)) return <></>

  return (
    <Flex flexDirection='column' gap={2}>
      <SectionHeader adornment={<IconTooltip title={tooltip} />}>
        {t(isBooked ? `${tPrefix}.bookingStepsCompleted` : 'screens.bookingModule.bookingSteps.title')}
      </SectionHeader>
      <Flex flexDirection='column' gap={3}>
        {showCountdown && <Countdown />}
        {canGetBookingSteps && <BookingSteps />}
      </Flex>
    </Flex>
  )
}

export { UnitManagerBookingSteps }
