import React, { useState } from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { PayInMethodLabel } from '@guiker/payment-app-components'
import { useAuthenticatedPaymentPayInMethodContext } from '@guiker/payment-methods-context'
import { DirectDebitPADPayInMethod, PayInMethod, typeChecker } from '@guiker/payment-shared'
import { OnAccountSelectedArgs, PlaidLink } from '@guiker/payment-verification-components'
import {
  FeedbackComponent,
  Flex,
  FullScreenSpinner,
  Note,
  P,
  PageLayout,
  PageSection4,
  useMutation,
  useNavigate,
} from '@guiker/react-framework'
import { optionalConcat } from '@guiker/shared-framework'

import { useT } from '../../i18n'

const getPayInMethodRoutingNumber = (payInMethod: PayInMethod) => {
  const { details } = payInMethod
  const payInMethodDetails = details as DirectDebitPADPayInMethod

  return optionalConcat([payInMethodDetails['institutionId'], payInMethodDetails['transitNumber']], '-')
}

export const InsufficientFundFlaggedScreen: React.FC = () => {
  const { tMain, tShared } = useT({ screenName: 'screens.insufficientFunds' })
  const { data: payInMethod, isFetching: isLoadingPayInMethod, apiClient } = useAuthenticatedPaymentPayInMethodContext()
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState(null)

  if (!typeChecker.isPAD(payInMethod)) navigate(webappRoutes.root.wallet().path)

  const {
    mutate: resolveInsufficientFundFlagged,
    isLoading: isLoadingResolution,
    status,
  } = useMutation(
    (args: { publicToken: string; bankAccountId: string }) => {
      const { publicToken, bankAccountId } = args

      return apiClient.resolveInsufficientFundFlagged({
        pathParams: { payInMethodId: payInMethod.id },
        payload: { publicToken, bankAccountId },
      })
    },
    {
      onSuccess: () => navigate(webappRoutes.root.wallet().payments().path),
    },
  )

  const onSelect = (args: OnAccountSelectedArgs) => {
    const { publicToken, auth, accountId } = args
    const payInMethodDetails = payInMethod.details
    const routingNumber = getPayInMethodRoutingNumber(payInMethod)

    if (auth.routingNumber !== routingNumber || auth.mask !== String(payInMethodDetails['accountNumber'])) {
      setErrorMsg(tMain('errorMessage'))
    } else {
      resolveInsufficientFundFlagged({ publicToken, bankAccountId: accountId })
    }
  }

  if (isLoadingPayInMethod) return <FullScreenSpinner />

  return (
    <PageLayout maxWidth={900} title={tMain('title')} subtitle={tMain('description')}>
      <PageSection4>
        {errorMsg && (
          <FeedbackComponent
            loading={status === 'loading'}
            toasterMessage={errorMsg}
            onToasterClose={() => setErrorMsg(undefined)}
          />
        )}
        <Note>
          <Flex flexDirection='column' gap={2}>
            <P color={'alert'}>{tMain('suggestedBankWarning')}</P>
            <PayInMethodLabel payInMethod={payInMethod} hideDisabledChip />
          </Flex>
        </Note>
        <PlaidLink
          isLoading={isLoadingResolution}
          label={tShared('connector.reconnect')}
          countryCode={payInMethod.region}
          plaidAccessToken={payInMethod.details['plaidAccessToken']}
          onSelect={onSelect}
          routingNumber={getPayInMethodRoutingNumber(payInMethod)}
          openOnLoad={false}
        />
      </PageSection4>
    </PageLayout>
  )
}
