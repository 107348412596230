import { buildQueueDefinition } from '@guiker/queue'
import { Listing as ImportedListing } from '@guiker/repliers-shared'

import { config } from '../config'

type DeleteListingPayload = {
  info: { mlsNumber: string; boardId: number; cityName: string }
  mute: boolean
}

export const Queue = {
  ProcessListingCreation: buildQueueDefinition({
    name: 'ProcessListingCreation',
    serviceName: config.apiName,
    payload: null as ImportedListing[],
    batchSize: 1,
    isFifo: true,
  }),
  ProcessListingUpdated: buildQueueDefinition({
    name: 'ProcessListingUpdated',
    serviceName: config.apiName,
    payload: null as ImportedListing[],
    batchSize: 1,
    isFifo: true,
  }),
  ProcessListingDeleted: buildQueueDefinition({
    name: 'ProcessListingDeleted',
    serviceName: config.apiName,
    payload: null as DeleteListingPayload[],
    batchSize: 1,
    isFifo: true,
  }),
}
