import React from 'react'

import { AnyColor } from '@guiker/components-core'

import { Flex } from '../../Layout'
import { PSmall } from '../Typography'

type Props = {
  content: React.ReactNode | string
  chip?: React.ReactNode
  color?: AnyColor
}

export const ActivityLabel: React.FC<Props> = ({ content, chip, color = 'textPrimary' }) => {
  return (
    <Flex gap={1} alignItems='center'>
      {!!chip && chip}
      <PSmall color={color}>{content}</PSmall>
    </Flex>
  )
}
