import { useState } from 'react'

export type UseModal = {
  isOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export const useModal = (open = false) => {
  const [isOpen, setIsOpen] = useState(open)
  return {
    isOpen,
    openModal: () => setIsOpen(true),
    closeModal: () => setIsOpen(false),
  }
}
