import React from 'react'

import { SecondaryButton, UseModal } from '@guiker/components-library'
import { PayoutMethod, payoutMethodInfoBuilder } from '@guiker/payout-shared'
import { ApiForm, ApiFormAction, Flex, P } from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient } from '../../../hooks'
import { useT } from '../../../i18n'

type ReplacementPayoutMethodConfirmationProps = {
  onClickBack: () => void
  payoutMethod: PayoutMethod
  replacement: PayoutMethod
  modal: UseModal
  setReplacement: React.Dispatch<React.SetStateAction<PayoutMethod>>
}

export const ReplacementPayoutMethodConfirmation: React.FC<ReplacementPayoutMethodConfirmationProps> = ({
  onClickBack,
  payoutMethod,
  replacement,
  modal,
  setReplacement,
}) => {
  const { tMain, tBase } = useT({ screenName: 'components.listPayoutMethods.replacementModal' })
  const apiClient = useAuthenticatedPayoutApiClient()

  const onSubmit = async () => {
    return apiClient.replacePayoutMethod({
      pathParams: { payoutMethodId: payoutMethod.id },
      payload: { replaceWithId: replacement.id },
    })
  }

  const onSuccess = () => {
    setReplacement(undefined)
    onClickBack()
    modal.closeModal()
  }

  return (
    <ApiForm formName={'replacementPayoutConfirmationForm'}>
      {({ formState: { isSubmitting } }) => (
        <Flex flexDirection='column'>
          <P>
            {tMain('confirmation', {
              payoutMethod: payoutMethodInfoBuilder(payoutMethod).getDisplay(),
              replacement: payoutMethodInfoBuilder(replacement).getDisplay(),
            })}
          </P>
          <Flex fullWidth justifyContent='flex-end' gap={2}>
            <SecondaryButton onClick={() => onClickBack()}>{tBase('actions.back')}</SecondaryButton>
            <ApiFormAction
              options={{
                onSuccess,
              }}
              onClick={onSubmit}
              isLoading={isSubmitting}
            >
              {tBase('actions.confirm')}
            </ApiFormAction>
          </Flex>
        </Flex>
      )}
    </ApiForm>
  )
}
