import { generateEventsDefinition } from '@guiker/event'

import { Quebec } from '../../entity'
import { eventTypes as LeaseEventTypes } from '../lease/event-types'
import { EventTypes as EventTypesEnum } from './event-types'

export const QuebecEventsDefinition = generateEventsDefinition({
  context: 'LEASE',
  entity: 'LEASE',
  data: null as unknown as Quebec.Lease,
  eventTypes: { ...LeaseEventTypes, ...EventTypesEnum },
})

export const { eventTypes: QuebecEventTypes, events: QuebecEvents } = QuebecEventsDefinition
