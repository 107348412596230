import { generateEventsDefinition, Roommate, ValueOfEventTypes } from '@guiker/shared-framework'

import { Applicant, Booking, InvitedApplicant, UnitManager } from '../../entity'
import { BaseBookingEvent } from '../base-event'

type ApplicantEventData = {
  applicant: Applicant
}
type ApplicantsEventData = {
  applicants: Applicant[]
}
type UnitManagerEventData = {
  unitManager: UnitManager
}
type UnitManagersEventData = {
  unitManagers: UnitManager[]
}
type TimerEventData = {
  hours: number
}
type InvitedApplicantsEventData = {
  invitedApplicants: InvitedApplicant[]
}

export const definition = generateEventsDefinition({
  ...BaseBookingEvent,
  entity: 'BOOKING',
  data: null as unknown as Booking,
  eventTypes: {
    APPLICANT_LEFT: {
      type: 'APPLICANT_LEFT',
      eventData: null as ApplicantEventData,
    },
    APPLICANTS_ADDED: {
      type: 'APPLICANTS_ADDED',
      eventData: null as ApplicantsEventData & InvitedApplicantsEventData,
    },
    BOOKED: 'BOOKED',
    BOOKED_WITH_ROOMMATES: {
      type: 'BOOKED_WITH_ROOMMATES',
      eventData: null as {
        roommates: Roommate[]
      },
    },
    CHARGE_FAILED: 'CHARGE_FAILED',
    PAYMENT_CONDITION_UPDATED: 'PAYMENT_CONDITION_UPDATED',
    CONFIRM_REQUESTED: 'CONFIRM_REQUESTED',
    RENT_PAYMENTS_REQUIRED: {
      type: 'RENT_PAYMENTS_REQUIRED',
      eventData: null as {
        payoutMethodId: string
        receiver: {
          userId: string
          firstName: string
          lastName?: string
          emailAddress: string
        }
      },
    },
    INFO_UPDATED: 'INFO_UPDATED',
    INVITATION_ACCEPTED: 'INVITATION_ACCEPTED',
    PROCESSING_PAY_IN_METHOD_VERIFICATION: 'PROCESSING_PAY_IN_METHOD_VERIFICATION',
    REJECTED: 'REJECTED',
    STARTED: 'STARTED',
    EXPIRED: 'EXPIRED',
    INQUIRY_CREATED: 'INQUIRY_CREATED',
    UNIT_MANAGER_ROLE_CHANGED: {
      type: 'UNIT_MANAGER_ROLE_CHANGED',
      eventData: null as UnitManagersEventData,
    },
    UNIT_MANAGER_LEFT: {
      type: 'UNIT_MANAGER_LEFT',
      eventData: null as UnitManagerEventData,
    },
    UNIT_MANAGERS_ADDED: {
      type: 'UNIT_MANAGERS_ADDED',
      eventData: null as UnitManagersEventData & { inviterUserId: string },
    },
    UNIT_MANAGERS_INVITED: {
      type: 'UNIT_MANAGERS_INVITED',
      eventData: null as UnitManagersEventData & { inviterUserId: string },
    },
    INVITED_LANDLORD_ADDED: {
      type: 'INVITED_LANDLORD_ADDED',
      eventData: null as UnitManagersEventData,
    },
    UNIT_MANAGERS_REPLACED: 'UNIT_MANAGERS_REPLACED',
    WITHDRAWN: {
      type: 'WITHDRAWN',
      eventData: null as ApplicantEventData,
    },
    TIMER_STARTED: {
      type: 'TIMER_STARTED',
      eventData: null as TimerEventData,
    },
    TIMER_RESTARTED: {
      type: 'TIMER_RESTARTED',
      eventData: null as TimerEventData,
    },
    TIMER_EXTENDED: {
      type: 'TIMER_EXTENDED',
      eventData: null as TimerEventData,
    },
    TIMER_EXTENDED_BY_UNIT_MANAGER: {
      type: 'TIMER_EXTENDED_BY_UNIT_MANAGER',
      eventData: null as TimerEventData,
    },
    TIMER_EXTENDED_BY_TENANT: {
      type: 'TIMER_EXTENDED_BY_TENANT',
      eventData: null as TimerEventData,
    },
    TIMER_1HR_MARKER_PASSED: 'TIMER_1HR_MARKER_PASSED',
    TIMER_6HR_MARKER_PASSED: 'TIMER_6HR_MARKER_PASSED',
    TIMER_STOPPED: 'TIMER_STOPPED',

    // post booking confirmed
    LEASE_TERM_EXTENDED: 'LEASE_TERM_EXTENDED',
    EARLY_TERMINATION_SET: 'EARLY_TERMINATION_SET',
    LISTING_UNIT_REPLACED: 'LISTING_UNIT_REPLACED',
    VOIDED: 'VOIDED',
  } as const,
})
export const { events: Events, eventTypes: EventTypes } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
