import React from 'react'

import { ActivityTracker, ActivityTrackerItem, Flex, P, PageSection4 } from '@guiker/react-framework'

import { useT } from '../../../i18n'

type Props = {
  items?: ActivityTrackerItem[]
}

const EmptyState: React.FC = () => {
  const { tShared } = useT({})

  return (
    <Flex fullWidth justifyContent='space-between'>
      <P>{tShared('activityLog.emptyState')}</P>
    </Flex>
  )
}

export const ActivityLog: React.FC<Props> = ({ items }) => {
  const { tShared } = useT({})
  const hasLogs = items?.length > 0

  return (
    <PageSection4 title={tShared(`activityLog.header`)}>
      {hasLogs ? <ActivityTracker items={items} /> : <EmptyState />}
    </PageSection4>
  )
}
