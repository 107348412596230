import React from 'react'

import { Button, RadioGroup } from '@guiker/components-library'
import { Payout } from '@guiker/payout-app-components'
import { PayoutMethod, payoutMethodInfoBuilder } from '@guiker/payout-shared'
import { Flex, P, PBold } from '@guiker/react-framework'

import { useReplacementPayoutMethods } from '../../../hooks'
import { useT } from '../../../i18n'

type ReplacementPayoutMethodSelectorProps = {
  onClickNext: (targetStep?: number) => void
  payoutMethod: PayoutMethod
  replacement: PayoutMethod
  setReplacement: React.Dispatch<React.SetStateAction<PayoutMethod>>
}

export const ReplacementPayoutMethodSelector: React.FC<ReplacementPayoutMethodSelectorProps> = ({
  onClickNext,
  payoutMethod,
  replacement,
  setReplacement,
}) => {
  const replacementPayoutMethods = useReplacementPayoutMethods(payoutMethod)
  const { tMain, tBase } = useT({ screenName: 'components.listPayoutMethods.replacementModal' })
  return (
    <Flex flexDirection='column'>
      <P>{tMain('select')}</P>
      <RadioGroup
        name='replacementPayoutMethodSelector'
        maxWidth='100%'
        adornmentPosition='start'
        defaultValue={replacement?.id}
        onChange={(e) => {
          const selected = replacementPayoutMethods.find(({ id }) => id.toString() === e.target.value)
          setReplacement(selected)
        }}
        options={replacementPayoutMethods.map((potentialReplacement) => {
          const label = payoutMethodInfoBuilder(potentialReplacement).getDisplay()

          return {
            value: potentialReplacement.id,
            label: <PBold>{label}</PBold>,
            adornment: <Payout.PayoutMethodIcon payoutMethod={potentialReplacement} />,
          }
        })}
      />
      <Flex fullWidth justifyContent='flex-end'>
        <Button disabled={!replacement} onClick={() => onClickNext()}>
          {tBase('actions.next')}
        </Button>
      </Flex>
    </Flex>
  )
}
