import React from 'react'

import { StockOrderStatus } from '@guiker/propsharing-shared'
import { Chip, ChipProps, useTranslation } from '@guiker/react-framework'

export const StatusChip: React.FC<{ status: StockOrderStatus | 'sold'; size: ChipProps['size'] }> = ({
  size,
  status,
}) => {
  const { t } = useTranslation('common-propsharing')

  switch (status) {
    case 'sold':
      return (
        <Chip size={size} color='success'>
          {t('stockOrderExecution.status.sold')}
        </Chip>
      )
    case StockOrderStatus.FULFILLED:
      return (
        <Chip size={size} color='success'>
          {t('stockOrderExecution.status.fulfilled')}
        </Chip>
      )
    case StockOrderStatus.PENDING_SHARE_ISSUANCE:
      return (
        <Chip size={size} color='info'>
          {t('stockOrderExecution.status.pendingShareIssuance')}
        </Chip>
      )
    case StockOrderStatus.PENDING_EXECUTION_COMPLETION:
      return (
        <Chip size={size} color='info'>
          {t('stockOrderExecution.status.processingShareIssuance')}
        </Chip>
      )
  }
}
