import React, { useEffect, useState } from 'react'

import { isEqual, ValueOf } from '@guiker/shared-framework'

import { Flex } from '../../Layout'
import { FilterProps } from './Filter'
import { FilterMultiSelect, FilterMultiSelectProps } from './FilterMultiSelect'

const ComponentMap = {
  FilterMultiSelect: FilterMultiSelect,
} as const

type MultiSelectFilter<Name> = FilterMultiSelectProps & {
  type: 'FilterMultiSelect'
  name: Name
}

type FilterMapping<Name, Values> = Values extends string | string[]
  ? MultiSelectFilter<Name>
  : FilterProps<unknown> & {
      type: keyof typeof ComponentMap
      name: Name
    }

export type FiltersProps<FiltersData> = {
  [name in keyof FiltersData]: name extends string ? FilterMapping<name, FiltersData[name]> : never
}

export type FilterGroupProps<FiltersData extends Record<string, any>> = React.PropsWithChildren & {
  filters: FiltersProps<FiltersData>
  onChange: (values: FiltersData) => unknown
  filtersData?: FiltersData
}

export const FilterGroup = <FiltersData extends Record<string, any>>({
  filters,
  onChange,
  filtersData: filtersDataProps,
}: FilterGroupProps<FiltersData>) => {
  const [previousFiltersData, setPreviousFiltersData] = useState<FiltersData>()
  const [filtersData, setFiltersData] = useState<FiltersData>(filtersDataProps ?? ({} as FiltersData))

  useEffect(() => {
    setFiltersData(filtersDataProps)
  }, [filtersDataProps])

  const onFilterChange = (name: string) => (value: unknown) => {
    setFiltersData((curr) => ({
      ...curr,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (previousFiltersData && !isEqual(previousFiltersData, filtersData)) {
      onChange(filtersData)
      setPreviousFiltersData(filtersData)
    } else if (!previousFiltersData) {
      setPreviousFiltersData(filtersData)
    }
  }, [filtersData])

  return (
    <Flex gap={1} flexDirection='row'>
      {Object.values(filters).map((filter: ValueOf<FiltersProps<FiltersData>>) => {
        const Component = ComponentMap[filter.type] as any
        return (
          <Component
            {...filter}
            defaultValue={filtersData[filter.name] as any}
            onChange={onFilterChange(filter.name)}
          />
        )
      })}
    </Flex>
  )
}
