import { PayoutMethod } from '../entity'

export const hasSameCapabilities = (args: { payoutMethod1: PayoutMethod; payoutMethod2: PayoutMethod }) => {
  const { payoutMethod1, payoutMethod2 } = args

  if (payoutMethod1.capabilities.length !== payoutMethod2.capabilities.length) {
    return false
  }

  const { capabilities = [] } = payoutMethod1

  return capabilities.every((c) => (payoutMethod2.capabilities ?? []).includes(c))
}
