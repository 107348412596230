import React, { useEffect } from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import { Dropdown, Flex, P, PBold, PSmall, Spinner, useFormContext } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { useGetPayInMethodLabelInfo } from '../../utils'
import { PayInMethodIcon } from '../PayInMethodIcon'

type Props = {
  showLabel?: boolean
  name?: string
  invoiceAmount?: number
}

export const MinimizedPayInMethodSelector: React.FC<Props> = ({ name, invoiceAmount, showLabel }) => {
  const { tShared } = useT()

  const { getLabelInfo } = useGetPayInMethodLabelInfo()
  const { region, setSelectedById, selected, payInMethods, isLoading } = usePayInMethodContext()
  const { setValue } = useFormContext()

  useEffect(() => {
    if (selected) {
      setValue(name, selected.id)
    }
  }, [selected])

  if (isLoading) return <Spinner />

  const enabledPayInMethods = payInMethods?.filter((p) => !p.disabled)
  if (!enabledPayInMethods?.length) return <P>{tShared('noPayInMethod')}</P>

  return (
    <Dropdown
      name={name}
      fullWidth
      onChange={(e) => setSelectedById(e.target.value)}
      defaultValue={selected?.id}
      label={showLabel && tShared('actions.selectPaymentMethod')}
      options={enabledPayInMethods?.map((payInMethod) => {
        const label = getLabelInfo({ payInMethod, region, amount: invoiceAmount })
        const { id, disabled, type, brand, brandName, routingNumber, lastDigits, secondaryLabel } = label

        return {
          value: id,
          disabled,
          label: (
            <Flex gap={2} alignItems='flex-start'>
              <PayInMethodIcon type={type} brand={brand} routingNumber={routingNumber} />
              <Flex flexDirection='column'>
                <Flex flexWrap='nowrap' gap={1}>
                  <PBold component='span'>{brandName}</PBold>
                  <P component='span' color={60}>
                    {lastDigits}
                  </P>
                </Flex>
                <PSmall component='span' color={30}>
                  {secondaryLabel}
                </PSmall>
              </Flex>
            </Flex>
          ),
        }
      })}
    />
  )
}
