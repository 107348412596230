import React, { useRef, useState } from 'react'

import { BookingPanel } from '@guiker/booking-components'
import { BookingContextProvider } from '@guiker/booking-context'
import { LegacyIFrame } from '@guiker/legacy-iframe'
import {
  ColumnGridLayout,
  DoubleChevronRightIcon,
  Drawer,
  Flex,
  isMobile,
  Label,
  makeStyles,
  padding,
  PageLayout,
  PSmall,
  TextButton,
  theme,
  toPx,
  useLocationQuery,
  useMediaQuery,
  useModal,
  useSearchParams,
  useT,
} from '@guiker/react-framework'

const useStyles = makeStyles(
  {
    root: {
      height: `calc(100dvh - ${toPx(theme.dimensions.appBar.height.desktop)})`,
      overflow: 'hidden',
      [isMobile]: {
        height: `calc(100dvh - ${toPx(theme.dimensions.appBar.height.mobile)})`,
      },
    },
    drawer: {
      width: '100%',
    },
    closeDrawer: {
      padding: padding(1, 2),
    },
    bookingContainer: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      [isMobile]: {
        maxWidth: 'initial',
      },
    },
    bookingPanel: {
      padding: padding(2, 2, 4, 2),
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  { name: 'InquiryChatScreen' },
)

type IMessage = {
  type: 'LOAD_BOOKING_PANEL' | 'OPEN_BOOKING_DRAWER'
  bookingId?: string
  conversationId?: string
}

const BookingContainer: React.FC<{ bookingId?: string }> = ({ bookingId }) => {
  const classes = useStyles()
  const containerRef = useRef(null)

  if (!bookingId) return <div></div>

  return (
    <Flex className={classes.bookingContainer} ref={containerRef}>
      <BookingContextProvider bookingId={bookingId} containerRef={containerRef}>
        <BookingPanel className={classes.bookingPanel} />
      </BookingContextProvider>
    </Flex>
  )
}

const buildSourceUrl = ({ convoId, bookingId }: { bookingId?: string; convoId?: string }) => {
  const baseUrl = '/myaccount/messages-embedded'
  const queryParams = bookingId ? `booking_id=${bookingId}` : convoId ? `convo_id=${convoId}` : ''
  return `${baseUrl}?${queryParams}`
}

export const InquiryChatScreen: React.FC = () => {
  const { tBase } = useT({})
  const { convoId, bookingId: paramBookingId } = useLocationQuery(['convoId', 'bookingId'])
  const searchParams = useSearchParams()
  const { isOpen, closeModal, openModal } = useModal(false)
  const [bookingId, setBookingId] = useState<string>()

  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const messageHandler = (message: IMessage) => {
    if (message.type === 'LOAD_BOOKING_PANEL') {
      setBookingId(message.bookingId)
      message.conversationId && searchParams.setParam('convoId', message.conversationId)
    } else if (message.type === 'OPEN_BOOKING_DRAWER') {
      openModal()
    }
  }

  return (
    <PageLayout noPadding noBreadcrumpSpacer>
      <ColumnGridLayout
        className={classes.root}
        gridTemplateColumns={[3, 1]}
        sm={{ gridTemplateColumns: [1], gap: 2 }}
        gap={0}
      >
        <LegacyIFrame<IMessage>
          height='100%'
          src={buildSourceUrl({ bookingId: paramBookingId, convoId })}
          messageHandler={messageHandler}
        />
        {isMobile ? (
          <Drawer open={isOpen} anchor='right' classes={{ paper: classes.drawer }}>
            <Flex justifyContent='flex-end' alignItems='center' className={classes.closeDrawer}>
              <TextButton underline={false} onClick={() => closeModal()}>
                <Label
                  adornment={{ node: <DoubleChevronRightIcon width={12} height={12} />, position: 'end' }}
                  text={<PSmall>{tBase('actions.close')}</PSmall>}
                />
              </TextButton>
            </Flex>
            <BookingContainer bookingId={bookingId} />
          </Drawer>
        ) : (
          <BookingContainer bookingId={bookingId} />
        )}
      </ColumnGridLayout>
    </PageLayout>
  )
}
