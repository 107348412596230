import React, { useRef } from 'react'

import { Flex, List, ListItem, Popover, PSmall, SecondaryButton, useModal } from '@guiker/components-library'
import { useT } from '@guiker/i18n'
import { CSVIcon, DownloadMiniIcon, LinkIcon } from '@guiker/icons'
import { copyToClipboard, safelyGetWindow } from '@guiker/react-utils'
import { Link } from '@guiker/router'

import { ExportCSVModal } from './ExportCSVModal'

type ExportCSVProps<T extends object, Status extends string> = {
  queryKey: string
  fetcher: (statuses: Status[]) => Promise<T[]>
  statuses?: Status[]
}

export const ExportCSV = <T extends object, Status extends string>({
  queryKey,
  fetcher,
  statuses,
}: ExportCSVProps<T, Status>) => {
  const { tBase } = useT()
  const exportModal = useModal()
  const menuPopover = useModal()
  const ref = useRef<HTMLDivElement>()

  const exportItems = [
    {
      text: tBase('csvExport.link'),
      icon: <LinkIcon />,
      onClick: () => copyToClipboard(safelyGetWindow()?.location?.href),
    },
    {
      text: tBase('csvExport.cta'),
      icon: <CSVIcon />,
      onClick: exportModal.openModal,
    },
  ]

  const onClick = () => {
    menuPopover.isOpen ? menuPopover.closeModal() : menuPopover.openModal()
  }

  return (
    <Flex justifyContent='flex-end'>
      {exportModal.isOpen && (
        <ExportCSVModal
          open={exportModal.isOpen}
          onClose={exportModal.closeModal}
          fetcher={fetcher}
          queryKey={queryKey}
          statuses={statuses?.length ? (statuses as Status[]) : undefined}
        />
      )}
      <div ref={ref}>
        <SecondaryButton size='small' onClick={onClick} startIcon={<DownloadMiniIcon />}>
          <PSmall>{tBase('csvExport.exportShare')}</PSmall>
        </SecondaryButton>
      </div>
      <Popover
        anchorEl={ref.current}
        open={menuPopover.isOpen}
        onClose={menuPopover.closeModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Flex flexDirection='column' justifyContent='space-between'>
          <List>
            {exportItems.map(({ text, icon, onClick }, index) => (
              <Link
                onClick={() => {
                  onClick && onClick()
                  menuPopover.closeModal()
                }}
              >
                <ListItem button key={`exportItem-${index}`}>
                  {icon}
                  <PSmall ml={1}>{text}</PSmall>
                </ListItem>
              </Link>
            ))}
          </List>
        </Flex>
      </Popover>
    </Flex>
  )
}
