import { buildQueueDefinition } from '@guiker/queue'

import { config } from '../config'

export const Queue = {
  ProcessSeriesSubscriptionAgreement: buildQueueDefinition({
    name: 'ProcessSeriesSubscriptionAgreement',
    serviceName: config.apiName,
    payload: null as {
      stockId: string
      userId: string
      ticker: string
      name: string
      quantity: number
      unitPrice: string
      purchaseValue: string
      purchaseDate: string
    }[],
    isFifo: true,
    batchSize: 1,
  }),
} as const
