import { routesBuilder } from '@guiker/shared-framework'

import { Payout, PayoutKPIs, WithPayoutMethod, WithTransfers } from '../../../entity'
import { paths } from '../paths'
import { readAllPayoutsSchema } from '../schemas'

const { buildGet } = routesBuilder()({
  basePath: paths.payout.all.base(),
})

export const routes = {
  readAllPayouts: buildGet({
    paginated: true,
    queryParamsSchema: readAllPayoutsSchema,
    response: null as WithTransfers<WithPayoutMethod<Payout>>,
  }),
  readPayoutKPIs: buildGet({
    path: '/kpis',
    response: null as PayoutKPIs,
  }),
}
